::-webkit-scrollbar {
  width: 1px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

::selection {
  background: #FA2BAA;
  color: #FFFFFF;
}

.fallback-loader {
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  background-color: #f9fbfd;
}

.fallback-loader-dark {
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  background-color: #0d1116;
}
