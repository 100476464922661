@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl; /*37px*/
  }
  h2 {
    @apply text-2xl; /*31px*/
  }
  h3 {
    @apply text-xl; /*21px*/
  }
  h4 {
    @apply text-lg; /*19px*/
  }
  h5 {
    @apply text-base; /*17px*/
  }
  h6 {
    @apply text-sm; /*12px*/
  }
}
ul.list {
  list-style-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7.33203' cy='6.66797' r='6' fill='%23ECF8F5'/%3E%3Cg clip-path='url(%23clip0_2081_1066)'%3E%3Cpath d='M9.49018 4.65544C9.60006 4.54453 9.74937 4.48163 9.90548 4.48048C10.0616 4.47933 10.2118 4.54003 10.3233 4.6493C10.4348 4.75857 10.4985 4.90754 10.5005 5.06364C10.5025 5.21975 10.4426 5.3703 10.334 5.48239L7.18981 9.413C7.13579 9.47115 7.07061 9.51783 6.99816 9.55023C6.92571 9.58264 6.84747 9.60011 6.76811 9.60161C6.68875 9.60311 6.60991 9.5886 6.53629 9.55895C6.46266 9.5293 6.39577 9.48511 6.3396 9.42904L4.25498 7.34522C4.14421 7.23439 4.08199 7.0841 4.08203 6.9274C4.08207 6.7707 4.14435 6.62043 4.25518 6.50965C4.36601 6.39888 4.51631 6.33666 4.67301 6.3367C4.82971 6.33674 4.97998 6.39902 5.09075 6.50985L6.73944 8.15894L9.47454 4.67389C9.47951 4.66754 9.48487 4.66152 9.49058 4.65584L9.49018 4.65544Z' fill='%23FA27A9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2081_1066'%3E%3Crect width='6.41667' height='6.41667' fill='white' transform='translate(4.08203 4.08203)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}
ul.list {
  margin-left: 20px;
}

label.radioBtn {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
label.radioBtn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 13px;
  left: 13px;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
label.radioBtn:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
label.radioBtn input:checked ~ .checkmark {
  background-color: #fa27a9;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
label.radioBtn input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
label.radioBtn .checkmark:after {
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}
.ant-alert-success {
  background-color: #42ba96 !important;
  border: none !important;
}
.ant-alert-success .ant-alert-message,
.ant-alert-error .ant-alert-message {
  color: white;
}
.ant-alert-error {
  background-color: #df4759 !important;
  border: none !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
